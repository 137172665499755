<template>
  <div>
    <gl-title title="订货订单详情" backIcon></gl-title>
    <statusCard :status="orderStatusText"/>
    <gl-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="订单信息" name="orderInfo">
          <orderInfo :info="orderInfo"/>
        </el-tab-pane>
        <el-tab-pane label="收货和物流信息" name="cargoInfo">
          <cargoInfo :info="orderInfo"/>
        </el-tab-pane>
      </el-tabs>
    </gl-card>
  </div>
</template>

<script>
import statusCard from './statusCard.vue'
import orderInfo from './orderInfo.vue'
import cargoInfo from './cargoInfo.vue'
import {fetchGoods} from '@/api/coox/orderGoods'
export default {
  name: 'orderDetail',
  components: {statusCard, orderInfo, cargoInfo},
  data(){
    return{
      activeName: 'orderInfo',
      orderId: '',
      statisKeys: {
        '1': '待支付',
        '2': '待发货',
        '3': '待收货',
        '4': '待评价',
        '5': '已完成',
        '6': '已取消',
        '7': '已关闭',
      },
      orderStatusText: null, //工单当前状态
      orderInfo: {}, //订单信息
    }
  },
  mounted(){
    this.orderId = this.$route.query.orderId
    this.fetchData()
  },
  methods: {
    async fetchData(){
      const res = await fetchGoods(this.orderId)
      this.orderStatusText = this.statisKeys[res.data.orderStatus]
      this.orderInfo = {
        ...res.data,
        orderStatusText: this.orderStatusText
      }
    },
    handleClick(tab, event){},
  },
}
</script>

<style>

</style>