<template>
  <div class="status-card">
    <div class="trade-status">
      <strong>
        当前订单状态：
        <span> {{status}} </span>
      </strong>
    </div>
    <!-- <div class="tbnotes">
      <span class="title">喜搭档提醒您</span>
      <div>交易已成功，如果买家提出售后要求，请积极与买家协商，做好售后服务。</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'statusCard',
  props: {
    status: {
      type: String,
      default: null
    },
  },
}
</script>

<style lang="scss" scoped>
.status-card{
  margin: 18px;
  border: 1px solid #F7E4A5;
  background: #FFFCF3;
  padding: 20px 30px;
  .trade-status{
    color: #404040;
    font-size: 12px;
    strong{
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .tbnotes{
    margin-top: 15px;
    border-top: 1px solid #ddd;
    padding-top: 5px;
    line-height: 21px;
    .title{
      color: #f60;
      font-weight: 700;
    }
  }
}
</style>